/* ==========================================================================
   #DESIGN
   ========================================================================== */

.c-design__inner {
  position: relative;
  background-color: #ff7142;
  text-align: center;
  padding: 180px 0 335px;
}

.c-design__content {
  max-width: 486px;
}

.c-design__title {
  line-height: (48/38);
  margin-bottom: 0;
}

.c-design__text {
  font-size: 18px;
  line-height: (27/18);
  margin-top: 9px;
}

.c-design__button {
  margin-top: 30px;
}

.c-design__target {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 122px;
  height: 61px;
}

.c-design__flower {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 109px;
  height: 227px;
}

.c-design__square {
  display: none;
}

@include bp(600px) {
  .c-design {
    padding-top: $offset * 1px;
    padding-bottom: $offset * 1px;
  }

  .c-design__inner {
    padding: 78px 70px;
    margin-left: $offset * 1px;
    margin-right: $offset * 1px;
  }

  .c-design__title {
    font-size: 32px;
  }

  .c-design__target {
    width: 90px;
    height: 45px;
  }

  .c-design__flower {
    margin: 0;
  }
}

@include bp-down(large) {
  .c-design__oval,
  .c-design__shape-2,
  .c-design__shape-1 {
    display: none;
  }
}

@include bp(large) {
  .c-design__flower {
    left: auto;
    right: 50%;
    margin-right: 340px;
  }

  .c-design__oval {
    position: absolute;
    right: -#{$offset}px;
    bottom: -#{$offset}px;
  }

  .c-design__shape-1 {
    position: absolute;
    right: 60px;
    top: -10px;
  }

  .c-design__shape-2 {
    position: absolute;
    left: 60px;
    top: 45px;
    transform: rotate(135deg);
  }
}

@include bp(1110px) {
  .c-design__square {
    display: block;
    position: absolute;
    left: auto;
    bottom: 0;
    right: 50%;
    margin-right: 450px;
  }
}
