/* ==========================================================================
   #CAROUSEL
   ========================================================================== */

.c-carousel {
  margin-top: 60px;
  margin-bottom: 60px;
}

.c-carousel__title {
  margin-bottom: 30px;
}

.c-carousel__text {
  line-height: (32/22);
  font-size: 22px;
}

.c-carousel__list {
  list-style-type: none;
  margin: -15px 0 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.c-carousel__list.is-active .c-carousel__item:nth-child(n + 5) {
  display: block;
}

.c-carousel__item {
  width: calc(50% - 8px);
  margin-top: 45px;
  text-align: center;
}

.c-carousel__item:nth-child(n + 5) {
  display: none;
}

.c-carousel__item-image {
  width: 90px;
  height: 90px;
  display: inline-block;
  border-radius: 50%;
  margin-bottom: 10px;
}

.c-carousel__item-name {
  font-size: 20px;
  line-height: normal;
  margin-bottom: 4px;
}

.c-carousel__item-position {
  color: #1c1714;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4px;
}

.c-carousel__item-logo {
  max-width: 98px;
  max-height: 35px;
}

.c-carousel__item-logo-wrapper {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.c-carousel__more {
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.7px;
  line-height: 1.82;
  text-transform: uppercase;
  background-color: $color-green-light;
  width: 100%;
  padding: 5px;
  margin-top: 35px;
  transition: background-color 0.4s;
}

.c-carousel__more:hover,
.c-carousel__more:active {
  background-color: #1a1a1a;
}

.c-carousel__nav {
  display: none;
}

@include bp(medium) {
  .c-carousel__text {
    margin-top: 19px;
    font-size: 23px;
    max-width: (635/668) * 100%;
  }

  .c-carousel__list {
    width: 100%;
    margin: 69px auto -40px;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;
  }

  .c-carousel__list-wrapper {
    position: relative;
  }

  .c-carousel__list-wrapper:after {
    content: '';
    display: block;
    position: absolute;
    left: -10px;
    right: -10px;
    bottom: -12px;
    height: 40px;
    background-color: #fff;
  }

  .c-carousel__item {
    display: block;
    width: 50%;
    flex-shrink: 0;
    flex-grow: 0;
    margin-top: 0;
    margin-bottom: 40px;
  }

  .c-carousel__item:nth-child(n + 5) {
    display: inline-block;
  }

  .c-carousel__item-image {
    width: 142px;
    height: 142px;
  }

  .c-carousel__item-name {
    margin-top: 20px;
  }

  .c-carousel__item-position {
    font-size: 16px;
  }

  .c-carousel__item-logo {
    max-width: 152px;
    max-height: 56px;
    width: auto;
    height: auto;
  }

  .c-carousel__item-logo-wrapper {
    margin-top: 15px;
    height: 56px;
  }

  .c-carousel__more {
    display: none;
  }

  .c-carousel__nav {
    display: block;
    width: 50px;
    text-align: center;
    position: absolute;
    top: 46px;
    height: 50px;
    color: #d7d7d7;
  }

  .c-carousel__nav--prev {
    right: 100%;
  }

  .c-carousel__nav--prev svg {
    transform: rotate(-180deg);
    transform-origin: 50% 50%;
  }

  .c-carousel__nav--next {
    left: 100%;
  }

  .c-carousel__nav svg {
    width: 26px;
    height: 50px;
    display: block;
    margin: 0 auto;
  }
}

@include bp(large) {
  .c-carousel {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .c-carousel__item {
    width: 33.333%;
  }

  .c-carousel__text {
    line-height: (40/30);
    font-size: 30px;
    max-width: (802/1112) * 100%;
  }
}

@include bp(xlarge) {
  .c-carousel__list {
    margin-top: 70px;
  }

  .c-carousel__item {
    width: 25%;
  }
}
