/* ==========================================================================
   #SLIDER
   ========================================================================== */

.c-slider {
  text-align: center;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  line-height: normal;

  &:before {
    content: '';
    display: block;
    width: 0;
    flex-grow: 0;
    height: calc(100vh - 80px);
    max-height: 720px;
  }
}

.c-slider__slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 0 80px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;

  &:not(.is-active) {
    height: 100%;
  }

  &.is-active {
    z-index: 1;
    position: relative;
    opacity: 1;
    visibility: visible;
  }
}

.c-slider__bg-link,
.c-slider__bg {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.c-slider__header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-slider__title {
  margin-bottom: 0;
  position: relative;
  max-width: 100%;
  padding-top: 60px;

  &--big {
    padding-top: 0;
  }
}

.c-slider__title-overlay {
  display: block;
  position: absolute;
  width: 150%;
  height: auto;
  left: 50%;
  bottom: 0;
  max-width: 100vw;
  transform: translate(-50%, 50%);
}

.c-slider__subtitle {
  line-height: normal;
  margin-top: 12px;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 0;
}

.c-slider__nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 44px;
  bottom: 120px;
  z-index: 2;
}

.c-slider__dot {
  padding: 6px;

  &.is-active > *,
  &:active > *,
  &:hover > * {
    background-color: $color-orange-light;
  }

  > * {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transition: background-color 0.3s;
    text-indent: -200vw;
    overflow: hidden;
    font-size: 0.1px;
    line-height: 0.1px;
    background-color: #cadcd9;
  }
}

.c-slider__icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background: $color-white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 50%;
}

.c-slider__footer {
  width: 100%;
  padding: 60px 47px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
  margin-bottom: auto;
}

.c-slider__buttons {
  margin-left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.c-slider__button + .c-slider__button {
  margin-top: 19px;
}

.c-slider__play {
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
  line-height: 1;
  font-size: 18px;

  .c-slider__icon {
    margin-right: 16px;
    vertical-align: -40%;
  }

  .c-slider__icon-image {
    margin-left: 10px;
  }
}

.c-slider__video {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: $color-black;
  transition: visibility 0.3s, opacity 0.3s;
  opacity: 0;
  visibility: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.is-active {
    opacity: 1;
    visibility: visible;
  }
}

@include bp-down(400px) {
  .c-slider__title--big {
    font-size: 110px;
  }
}

@include bp-between(400px, medium) {
  .c-slider__title--big {
    font-size: 136px;
  }
}

@include bp(medium) {
  .c-slider:before {
    max-height: 820px;
    min-height: 550px;
  }

  .c-slider__slide {
    padding: 70px 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .c-slider__title {
    padding-top: 0;
  }

  .c-slider__nav {
    right: 48px;
    bottom: 62px;
  }

  .c-slider__header {
    margin-top: -120px;
  }

  .c-slider__footer {
    position: absolute;
    bottom: 70px;
    left: 0;
    right: 0;
    padding: 0 55px;
  }

  .c-slider__play {
    margin-top: 18px;
    margin-bottom: 18px;
  }

  .c-slider__title-overlay {
    min-width: 540px;
  }

  .c-slider__button + .c-slider__button {
    margin-top: 23px;
  }
}

@include bp(large) {
  .c-slider__slide {
    padding: 60px 0;
  }

  .c-slider__nav {
    right: 30px;
  }

  .c-slider__header {
    margin-top: -180px;
  }

  .c-slider__footer {
    bottom: 60px;
    padding: 0 65px;
  }

  .c-slider__play {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .c-slider__title-overlay {
    min-width: 838px;
  }
}
