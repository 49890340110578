/* ==========================================================================
   #RELATED CONTENT
   ========================================================================== */

.c-related-content {
  margin-top: 60px;
  margin-bottom: 40px;
}

.c-related-content__separator {
  margin-bottom: 40px;
}

.c-related-content__header {
  margin-bottom: 30px;
}

.c-related-content__link {
  line-height: (36/30);
  font-size: 30px;
  margin-bottom: 40px;
}
