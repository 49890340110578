/* ==========================================================================
   #CONTAINER NARROW
   ========================================================================== */

.o-container-narrow {
  margin-right: auto;
  margin-left: auto;
  max-width: 800px;
}

@include bp($container-narrow-width * 1px) {
  .o-container-narrow .o-container-narrow__inner {
    margin-left: 35px;
    margin-right: auto;
    width: 676px;
  }
}
