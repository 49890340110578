/* ==========================================================================
   #COLUMNS
   ========================================================================== */

.c-columns {
  margin-top: 60px;
  margin-bottom: 60px;
}

.c-columns__title {
  margin-bottom: 60px;
}

.c-columns__media {
  margin-bottom: 15px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.c-columns__icon {
  display: block;
  width: auto;
  height: auto;
}

.c-columns--big .c-columns__text {
  font-size: 18px;
  line-height: (27/18);
}

.c-columns__subtitle {
  font-size: 24px;
  margin-bottom: 13px;
  line-height: 1;
}

.c-columns--big .c-columns__subtitle {
  font-size: 39px;
}

.c-columns__column {
  margin-bottom: 60px;
}

@include bp(540px) {
  .c-columns__items {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-left: -(60/668) * 100%;
    margin-bottom: -60px;
  }

  .c-columns__column {
    width: (185/735) * 100%;
    margin-left: (60/735) * 100%;
  }
}

@include bp($container-narrow-width * 1px) {
  .c-columns--narrow .c-columns__inner {
    //width: 100%;
  }
}

@include bp(large) {
  .c-columns--wide .c-columns__items {
    min-width: 703px;
  }

  .c-columns__text--big {
    font-size: 18px;
    line-height: (27/18);
  }
}

@include bp(xlarge) {
  .c-columns--wide .c-columns__items {
    width: 1080px;
    margin-left: -120px;
  }

  .c-columns--wide .c-columns__column {
    width: 240px;
    margin-left: 120px;
  }
}
