/* ==========================================================================
   #SEPARATOR
   ========================================================================== */

.c-separator {
  margin-top: 80px;
  margin-bottom: 80px;
  border: none;
  border-top: 2px solid #f8f8f8;
}

@include bp(medium) {
  .c-separator {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
