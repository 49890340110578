/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  height: 80px;
}

.c-header__wrapper {
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  background-color: $color-white;
}

.c-header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

.c-header__logo {
  display: flex;
  align-items: center;
  justify-content: center;

  &:active,
  &:hover {
    color: $color-orange-light;
  }
}

.c-header__logo-icon {
  display: block;
}

.c-header__logo-text {
  display: none;
}

.c-header__menu {
  margin-bottom: 0;
}

.c-header__menu-item {
  margin-bottom: 0;

  @at-root {
    .single-work &.is-work,
    .single-post &.is-labs,
    &.is-active,
    &:active,
    &:hover {
      color: $color-orange-light;
    }
  }
}

.c-header__burger {
  &:active {
    color: $color-orange-light;
  }
}

.c-header__burger-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@include bp-down(large) {
  .c-header__wrapper {
    transition: background-color 0.3s;
  }

  .c-header__menu {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: calc(100vh - 100%);
    background-color: $color-green-dark;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: normal;
    font-weight: 700;
    font-size: 56px;
    color: $color-white;
    transition: opacity 0.3s, visibility 0.3s;
    opacity: 0;
    visibility: hidden;
  }

  .c-header__menu-item {
    &:first-child {
      margin-top: auto;
    }

    &:last-child {
      margin-bottom: auto;
    }
  }

  @at-root .is-menu-open {
    .c-header__wrapper {
      color: $color-white;
      background-color: $color-green-dark;
    }

    .c-header__menu {
      opacity: 1;
      visibility: visible;
      -webkit-overflow-scrolling: touch;
    }
  }
}

@include bp(large) {
  .c-header__menu-item {
    margin-left: 45px;
  }

  .c-header__logo-icon {
    display: none;
  }


  .c-header__logo-text {
    display: block;
    line-height: normal;
    font-size: 26px;
  }

  .c-header__menu {
    line-height: normal;
    font-size: 18px;
    display: flex;
  }

  .c-header__burger {
    display: none;
  }
}

@include bp-down(medium) {
  .c-header__container {
    padding: 0 $offset * 1px;
  }
}
