/* ==========================================================================
   #SOCIAL
   ========================================================================== */

.c-social__title {
  font-size: 18px;
  margin-bottom: 20px;
  width: 100%;
}

.c-social__inner {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.c-social__link {
  width: 34px;
  height: 34px;
  background-color: #efeeee;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-text;
  transition: background-color 0.3s, color 0.3s;
  margin-right: 10px;
  margin-top: 10px;

  &.is-active,
  &:hover,
  &:active {
    background-color: $color-text;
    color: #efeeee;
  }
}

.c-social__links {
  display: flex;
  margin-right: -10px;
  margin-top: -10px;
  position: relative;
}

.c-social__more {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  left: 0;
  top: 100%;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 0;
  visibility: hidden;
  width: #{4*44}px;

  &.is-active {
    opacity: 1;
    visibility: visible;
  }
}

.c-social__more-trigger .c-social__icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@include bp(420px) {
  .c-social__more {
    width: #{8*44}px;
  }
}

@include bp(600px) {
  .c-social__more {
    top: 0;
    left: 100%;
  }
}
