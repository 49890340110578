/* ==========================================================================
   #LINKS
   ========================================================================== */

.o-link-green-dark {
  color: $color-green-dark;

  &:hover,
  &:active {
    color: $color-green-light;
  }
}

.o-link-green-light {
  color: $color-green-light;

  &:hover {
    color: $color-green;
  }

  &:active {
    color: $color-green-dark;
  }
}

.o-link-grey-light {
  color: $color-grey-light;

  &:hover,
  &:active {
    color: $color-green-light;
  }
}

.o-link-text {
  color: $color-green-light;

  &:hover {
    color: $color-green;
  }

  &:active {
    color: $color-orange;
  }
}

.o-links-green-dark a {
  @extend .o-link-green-dark;
}

.o-links-green-light a {
  @extend .o-link-green-light;
}

.o-links-grey-light a {
  @extend .o-link-grey-light;
}

.o-links-text a {
  @extend .o-link-text;
}
