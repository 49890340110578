/* ==========================================================================
   #TEAM
   ========================================================================== */

.c-team {
  padding: 120px 0 180px;
  margin-top: 80px;
  margin-bottom: 80px;
  min-height: 1220px;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../assets/images/bg-the-herd-pattern.svg') center top;
    background-size: auto 610px;
  }
}

.c-team__title {
  margin-bottom: 30px;
}

.c-team__inner {
  position: relative;
}

.c-team__headline {
  line-height: (32/22);
  font-size: 22px;
  margin-bottom: 40px;
}

.c-team__members {
  margin-bottom: -50px;
}

.c-team__member {
  margin-bottom: 50px;
}

.c-team__image {
  width: 202px;
  height: 202px;
  display: block;
  margin-bottom: 20px;
  border-radius: 50%;
}

.c-team__name {
  font-size: 28px;
  line-height: normal;
  margin-bottom: 6px;
}

.c-team__position {
  font-size: 18px;
  line-height: normal;
  margin-bottom: 4px;
}

.c-team__link {
  font-size: 18px;
  line-height: normal;
}

.c-team__main-link {
  position: absolute;
  width: 47px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: calc(50% - 98px);
  top: 106px;
}

.c-team__main-link-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-team__main-link-icon {
  width: 18px;
  height: 18px;
  display: block;
  position: relative;
  fill: $color-white;
  margin-left: -4px;
  margin-top: -4px;
}

@include bp(640px) {
  .c-team {
    padding-top: 80px;
    padding-bottom: 100px;
    min-height: 862px;
    margin-bottom: 110px;

    &:before {
      background-size: auto 862px;
    }
  }

  .c-team__member {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 40px;
  }

  .c-team__image {
    margin-bottom: 0;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .c-team__member-info {
    margin-left: 24px;
  }

  .c-team__headline {
    width: (520/668) * 100%;
    font-size: 27px;
    margin-bottom: 70px;
    max-width: 520px;
  }

  .c-team__title {
    margin-bottom: 23px;
  }

  .c-team__main-link {
    width: 62px;
    height: 78px;
    right: calc(50% - 262px);
    top: 133px;
  }
}

@include bp(large) {
  .c-team {
    margin-top: 110px;
    margin-bottom: 110px;
    padding-top: 140px;
    padding-bottom: 240px;

    &:before {
      background-repeat: no-repeat;
    }
  }

  .c-team__headline {
    font-size: 30px;
    margin-bottom: 122px;
  }

  .c-team__members {
    margin-top: -40px;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
  }

  .c-team__member {
    margin-bottom: 0;
    margin-top: 40px;
    width: 50%;
    padding-right: 20px;
  }

  .c-team__main-link {
    width: 78px;
    height: 98px;
    right: calc(50% - 262px);
    top: 133px;
  }

  .c-team__main-link-icon {
    width: 22px;
    height: 22px;
    margin-left: -6px;
    margin-top: -6px;
  }
}
