/* ==========================================================================
   #CASE STUDIES
   ========================================================================== */

.c-case-studies__image-wrapper {
  position: relative;
  display: block;
  width: 100%;
  padding-top: (480/768) * 100%;
  box-shadow: 0 2px 13px rgba(0, 0, 0, 0.152853);
  transition: box-shadow 0.3s;

  @at-root {
    a#{&}:hover,
    a#{&}:active {
      box-shadow: none;
    }
  }
}

.c-case-studies__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
}

@include bp-down(large) {
  .c-case-studies__client,
  .c-case-studies__title,
  .c-case-studies__text,
  .c-case-studies__button {
    display: none;
  }
}

@include bp(large) {
  .c-case-studies {
    margin-bottom: 82px;
  }

  .c-case-studies__items {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -82px;
  }

  .c-case-studies__item {
    width: 50%;
    margin-bottom: 82px;
  }

  .c-case-studies__client {
    font-size: 15px;
    margin-top: 24px;
    line-height: normal;
  }

  .c-case-studies__title {
    font-size: 22px;
    margin-top: 17px;
    margin-bottom: 0;
    line-height: (30/22);
    padding-right: (135/636) * 100%;
  }

  .c-case-studies__text {
    font-size: 18px;
    line-height: (27/18);
    margin-top: 2px;
    padding-right: (135/636) * 100%;
  }

  .c-case-studies__button {
    margin-top: 23px;
  }
}
