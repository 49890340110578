/* ==========================================================================
   #IMAGES
   ========================================================================== */

.c-images {
  margin-top: #{$offset-small}px;
  margin-bottom: #{$offset-small}px;
}

.c-images__inner {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: - #{$offset-small}px;
  align-items: flex-start;
  justify-content: flex-start;
}

.c-images__image {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: #{$offset-small}px;
}


@include bp(medium) {
  .c-images {
    margin-top: #{$offset}px;
    margin-bottom: #{$offset}px;
  }

  .c-images__inner {
    margin-bottom: - #{$offset}px;
  }

  .c-images__image {
    margin-bottom: #{$offset}px;
  }

  .c-images__image--small {
    width: calc(100% - #{2 * $offset}px);
    margin-left: #{$offset}px;
  }
}

@include bp(large) {
  .c-images__image--small {
    width: calc(50% - #{1.5 * $offset}px);
  }
}

@include bp(full) {
  .c-images {
    margin-top: #{$offset-large}px;
    margin-bottom: #{$offset-large}px;
  }

  .c-images__inner {
    margin-left: -#{$offset-large}px;
    margin-bottom: - #{$offset-large}px;
  }

  .c-images__image {
    margin-bottom: #{$offset-large}px;
    margin-left: #{$offset-large}px;
    width: calc(100% - #{$offset-large}px);
  }

  .c-images__image--small {
    width: calc(50% - #{$offset-large}px);
  }
}
