/* ==========================================================================
   #POSTER
   ========================================================================== */

.c-poster {
  padding: 100px 0;
}

.c-poster__title {
  font-weight: 700;
  line-height: 1;
  font-size: 48px;
  margin-bottom: 0;
}

.c-poster__text {
  margin-top: 13px;
  line-height: (32/22);
  font-size: 18px;
}

.c-poster__button {
  margin-top: 22px;
}

.c-poster__media {
  display: none;
}

@include bp(400px) {
  .c-poster__title {
    font-size: 68px;
  }

  .c-poster__text {
    font-size: 22px;
  }
}

@include bp(medium) {
  .c-poster {
    margin-top: #{$offset}px;
    margin-bottom: #{$offset}px;
    overflow: hidden;
  }

  .c-poster__content {
    width: 85%;
  }

  .c-poster__title {
    font-size: 78px;
  }

  .c-poster__text {
    width: 90%;
    font-size: 23px;
    margin-top: 17px;
  }

  .c-poster__media {
    display: block;
    margin-right: -50px;
    margin-top: -140px;
    margin-bottom: -65px;
  }

  .c-poster__image {
    display: block;
    width: 424px;
    height: auto;
    margin-right: -222px;
    margin-left: auto;
  }
}

@include bp(large) {
  .c-poster__title {
    font-size: 108px;
  }

  .c-poster__text {
    margin-top: 29px;
    font-size: 24px;
    width: (610/674) * 100%;
  }

  .c-poster__button {
    margin-top: 24px;
  }

  .c-poster__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .c-poster__content {
    width: (680/1130) * 100%;
    flex-shrink: 0;
  }

  .c-poster__media {
    margin: 0 0 0 25px;
    flex-shrink: 1;
    flex-grow: 1;
  }

  .c-poster__image {
    max-width: none;
    margin: 100px auto -40px 0;
  }
}

@include bp(xlarge) {
  .c-poster {
    padding: 160px 0;
  }

  .c-poster__inner {
    width: calc(100% - 140px);
  }
}

@include bp(full) {
  .c-poster {
    margin: $offset-large * 1px auto;
  }
}
