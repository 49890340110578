/* ==========================================================================
   #LOGOS
   ========================================================================== */

.c-logos {
  margin-top: 50px;
  margin-bottom: 50px;
}

.c-logos__title {
  margin-bottom: 10px;
}

.c-logos__headline {
  line-height: (32/22);
  font-size: 22px;

  a {
    text-decoration: underline;
  }
}

.c-logos__image {
  display: block;
  width: 202px;
  height: auto;
  max-height: 95px;
  margin: auto;
}

.c-logos__items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
}

.c-logos__item {
  width: calc(50% - 20px);
  text-align: center;
  margin-top: 45px;
  margin-left: 20px;
}

@include bp(medium) {
  .c-logos {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .c-logos__title {
    margin-bottom: 13px;
  }

  .c-logos__headline {
    line-height: (32/23);
    font-size: 23px;
  }
}

@include bp(large) {
  .c-logos {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .c-logos__headline {
    line-height: (40/30);
    font-size: 30px;
  }

  .c-logos__title {
    margin-bottom: 15px;
  }
}

@include bp(540px) {
  .c-logos__item {
    width: calc(33.33% - 20px);
  }
}

@include bp(medium) {
  .c-logos__items {
    margin-left: -30px;
  }

  .c-logos__item {
    width: calc(25% - 30px);
    margin-left: 30px;
    margin-top: 50px;
  }
}

@include bp(large) {
  .c-logos__items {
    margin-left: 0;
  }

  .c-logos__item {
    margin-top: 60px;
    margin-left: 0;
    width: 25%;
  }
}
