/* ==========================================================================
   #TEXT
   ========================================================================== */

.c-text {
  margin-top: 50px;
  margin-bottom: 50px;
}

@include bp(medium) {
  .c-text {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
