/* ==========================================================================
   #TEXT
   ========================================================================== */

.o-text {
  font-size: 23px;
  line-height: (36/23);

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3, h4, h5 {
    font-size: 1em;
  }

  blockquote {
    margin: 1em 30px;
    font-style: normal;
  }

  img {
    display: block;
    width: calc(100% + #{2 * $offset}px);
    height: auto;
    margin: 0 -#{$offset}px;
    max-width: none;
  }
}

@include bp(medium) {
  .o-text {
    blockquote {
      margin-left: 70px;
      margin-right: 70px;
    }

    img {
      margin-left: -50px;
      margin-right: -50px;
      width: calc(100% + 100px);
    }
  }
}

@include bp($container-narrow-width * 1px) {
  .o-text {
    img {
      margin-left: -35px;
      margin-right: 0;
      width: $container-narrow-width * 1px;
    }
  }
}
