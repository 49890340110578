/* ==========================================================================
   #NO OUTSTANDING MARGINS
   ========================================================================== */

.o-no-edge-margins > *:first-child {
  margin-top: 0;
}

.o-no-edge-margins > *:last-child {
  margin-bottom: 0;
}
