/* ==========================================================================
   #WORKS
   ========================================================================== */

.c-works {
  background-color: #f8f8f8;
  padding: 25px 0 110px;
  text-align: center;
}

.c-works__items {
  text-align: left;
}

.c-works__item {
  margin-bottom: 90px;
}

.c-works__media {
  display: block;
  box-shadow: 0 2px 13px rgba(0, 0, 0, 0.152853);
  margin-left: -13px;
  margin-right: -13px;
  margin-bottom: 47px;
  transition: box-shadow 0.3s;

  &:hover,
  &:active {
    box-shadow: none;
  }
}

.c-works__image {
  display: block;
  width: 100%;
  height: auto;
}

.c-works__title {
  line-height: (25/22);
  font-size: 22px;
  margin-bottom: 0;
}

.c-works__client {
  margin-top: 10px;
  font-size: 14px;
}

.c-works__categories {
  font-size: 14px;
  margin-top: 15px;
}

.c-works__more {
  margin-top: 90px;
}

.c-works.no-more-button .c-works__more {
  display: none;
}

@include bp(medium) {
  .c-works {
    padding-top: 45px;
    padding-bottom: 90px;
  }

  .c-works__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
  }

  .c-works__item:nth-child(even) {
    flex-direction: row-reverse;
  }

  .c-works__media {
    margin-bottom: 0;
    margin-right: 0;
    margin-left: -10px;
    width: (380/668) * 100%;
  }

  .c-works__item:nth-child(even) .c-works__media {
    margin-right: -10px;
    margin-left: 0;
  }

  .c-works__content {
    width: (263/668) * 100%;
  }

  .c-works__more {
    margin-top: 90px;
  }

  .c-works__client {
    margin-top: 15px;
  }
}

@include bp(xlarge) {
  .c-works {
    padding-top: 55px;
    padding-bottom: 100px;
  }

  .c-works__item {
    margin-bottom: 70px;
  }

  .c-works__media {
    width: (674/1112) * 100%;
    margin-left: -40px;
  }

  .c-works__item:nth-child(even) .c-works__media {
    margin-right: -40px;
  }

  .c-works__content {
    width: (420/1112) * 100%;
  }

  .c-works__title {
    line-height: (34/32);
    font-size: 32px;
  }

  .c-works__client {
    font-size: 16px;
    margin-top: 18px;
  }

  .c-works__more {
    margin-top: 90px;
  }
}
