/* ==========================================================================
   #HERO
   ========================================================================== */

.c-hero {
  margin-top: 70px;
  margin-bottom: 70px;
}

.c-hero__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-hero__header {
  font-size: 14px;
  margin-bottom: 44px;
}

.c-hero__title {
  margin-bottom: 30px;
}

.c-hero__headline {
  line-height: (32/23);
  font-size: 23px;
  margin: 0 auto 30px;
}

.c-hero__footer {
  margin-bottom: 15px;
}

.c-hero__date {
  font-size: 14px;
  margin-bottom: 20px;
}

.c-hero__social {
  margin: auto auto 0;
}

.c-hero__social .c-social__inner {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

@include bp(420px) {
  .c-hero__social .c-social__more {
    top: 100%;
  }
}

@include bp-down(medium) {
  .c-hero {
    display: flex;

    &:before {
      content: '';
      width: 0;
      display: block;
      height: calc(100vh - #{80 + 140}px);
      flex-grow: 0;
    }
  }
}

@include bp-down(large) {
  .c-hero__title br {
    display: none;
  }
}

@include bp(420px) {
  .c-hero__social .c-social__more {
    left: -#{2*44}px;
  }
}

@include bp(medium) {
  .c-hero {
    margin-top: 130px;
    margin-bottom: 75px;
  }

  .c-hero__inner {
    padding: 0;
    display: block;
    max-width: 908px;
  }

  .c-hero__headline {
    width: (580/668) * 100%;
  }

  .c-hero__social {
    margin-top: 65px;
  }

  .c-hero__header {
    margin-bottom: 50px;
  }

  .c-hero__title {
    margin-bottom: 40px;
  }
}

@include bp(large) {
  .c-hero__headline {
    width: (676/904) * 100%;
    font-size: 30px;
    line-height: (40/30);
  }
}

@include bp(xlarge) {
  .c-hero {
    margin-bottom: 45px;
  }
}
