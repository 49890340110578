/* ==========================================================================
   #TESTIMONIALS
   ========================================================================== */

.c-testimonials {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 21'%3E %3Cpath d='M4 2c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2z' fill-rule='evenodd' clip-rule='evenodd' fill='%23d7f2ed'/%3E %3C/svg%3E") repeat left top;
  background-size: 21px 21px;
  padding: 80px 0;
}

.c-testimonials__items {
  position: relative;
}

.c-testimonials__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 0;
  visibility: hidden;

  &.is-active {
    position: relative;
    opacity: 1;
    visibility: visible;
  }
}

.c-testimonials__title {
  margin-bottom: 70px;
}

.c-testimonials__media {
  margin-bottom: 30px;
}

.c-testimonials__image {
  display: block;
  width: 74px;
  height: 74px;
  border-radius: 50%;
}

.c-testimonials__quote {
  line-height: (32/22);
  font-size: 22px;
  margin-bottom: 20px;
}

.c-testimonials__author {
  color: #000;
  line-height: (24/16);
  font-style: normal;
}

.c-testimonials__position {
  color: #999ea8;
  line-height: (24/16);
}

.c-testimonials__nav {
  margin-top: 41px;
  display: flex;
  justify-content: center;
  margin-bottom: -9px;
}

.c-testimonials__dot {
  padding: 9px;
}

.c-testimonials__dot-number {
  font-size: 0.1px;
  line-height: 0.1px;
  position: relative;
  text-indent: -200vw;
  width: 13px;
  height: 13px;
  display: block;
  border-radius: 50%;
  background-color: #cadcd9;
  transition: background-color 0.3s;
}

.c-testimonials__dot.is-active .c-testimonials__dot-number,
.c-testimonials__dot:hover .c-testimonials__dot-number {
  background-color: $color-orange-light;
}

@include bp(medium) {
  .c-testimonials {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .c-testimonials__items {
    width: (583/668) * 100%;
  }

  .c-testimonials__item {
    display: flex;
  }

  .c-testimonials__quote {
    margin-bottom: 30px;
    line-height: (36/27);
    font-size: 27px;
  }

  .c-testimonials__media {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 30px;
  }
}

@include bp(large) {
  .c-testimonials {
    padding: 120px 0;
  }

  .c-testimonials__title {
    margin-bottom: 75px;
  }

  .c-testimonials__items {
    width: 672px;
    margin-left: auto;
    margin-right: auto;
  }

  .c-testimonials__nav {
    width: 672px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 103px;
    justify-content: flex-start;
  }
}
