/* ==========================================================================
   #HEADINGS
   ========================================================================== */

.o-h1,
.o-h2,
.o-h3 {
  font-weight: 700;
  line-height: 1;
}

.o-h1 {
  font-size: 66px;
}

.o-h2 {
  font-size: 50px;
}

.o-h3 {
  font-size: 38px;
}

@include bp(medium) {
  .o-h1 {
    font-size: 136px;
  }

  .o-h2 {
    font-size: 65px;
  }
}


@include bp(large) {
  .o-h1 {
    font-size: 196px;
  }

  .o-h2 {
    font-size: 65px;
  }
}
