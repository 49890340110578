/* ==========================================================================
   #HR
   ========================================================================== */

.o-hr {
  display: block;
  border: none;
  border-top: 2px solid #ebecee;
  margin: 0;
}
