/* ==========================================================================
   #PAGE
   ========================================================================== */

html {
  background: $color-bg;
  color: $color-text;
  font-family: $font-sans-serif;
  font-size: 16px;
  line-height: 1.286;
  min-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

/* stylelint-disable-next-line selector-no-qualifying-type, rule-empty-line-before */
html.hide-mailchimp [id^='PopupSignupForm'] {
  display: none;
}
