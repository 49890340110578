/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: currentColor;
  text-decoration: none;
  transition: 0.3s color, box-shadow 0.3s;
  text-decoration-skip: ink;

  &:hover {
    color: currentColor;
    text-decoration: none;
  }
}
