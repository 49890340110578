/* ==========================================================================
   #WRAPPER
   ========================================================================== */

.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  width: calc(100% - 60px);
}

@include bp(medium) {
  .o-wrapper {
    width: calc(100% - 100px);
  }
}

@include bp(xlarge) {
  .o-wrapper {
    width: calc(100% - 160px);
  }
}
