/* ==========================================================================
   #INPUT
   ========================================================================== */

button, input, textarea, select {
  font-family: inherit;
  color: inherit;
  font-weight: inherit;
  background: transparent;
  border: none;
  padding: 0;
  border-radius: 0;
}

input, textarea, select {
  line-height: normal;
}

button {
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
}

input, textarea {
  &::placeholder {
    opacity: 1;
    color: currentColor;
  }
}
