/* ==========================================================================
   #POSTS
   ========================================================================== */

.c-posts {
  margin-top: 80px;
  margin-bottom: 80px;
}

.c-posts__headline {
  line-height: (32/22);
  font-size: 22px;
  margin-bottom: 50px;
}

.c-posts__filter-sort {
  background-color: #fae9e8;
  width: 100%;
  display: block;
}

.c-posts__filter-sort-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 20px;
  line-height: 1;
}

.c-posts__filter-chevron {
  width: 22px;
  height: 9px;
  display: block;
  filter: drop-shadow(0 4px 2px rgba(0, 0, 0, 0.25));
  transition: filter 0.3s, fill 0.3s;
}

.c-posts__filter-sort:hover .c-posts__filter-chevron {
  filter: none;
}

.c-posts__filter-button {
  transition: color 0.3s;
}

.c-posts__filter-button.is-active {
  color: $color-orange;
}

.c-posts__items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  margin-top: -30px;
  position: relative;

  &:after {
    display: block;
    content: '';
    transition: opacity 0.3s, visibility 0.3s;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: -10px;
    right: -10px;
    top: -10px;
    bottom: -10px;
    background-color: rgba(#fff, 0.85);
  }
}

.c-posts.is-loading .c-posts__items:after {
  visibility: visible;
  opacity: 1;
}

.c-posts__item {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.17);
  margin-top: 30px;
  width: 100%;
  max-width: 303px;
}

.c-posts__media {
  display: block;
  position: relative;

  &:empty {
    display: none;
  }

  padding-top: (135/303) * 100%;
}

.c-posts__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-posts__content {
  padding: 30px 20px 40px;
}

.c-posts__categories {
  font-size: 14px;
  line-height: normal;
  margin-bottom: 13px;
}

.c-posts__title {
  margin-bottom: 0;
  line-height: (28/20);
  font-size: 20px;
}

.c-posts__footer {
  text-align: center;
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.c-posts__filter {
  margin-bottom: 45px;
}

.c-posts.no-more-button .c-posts__footer {
  display: none;
}

@include bp-down(medium) {
  .c-posts__filter {
    position: relative;
    max-width: 420px;
  }

  .c-posts__filter-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    transition: opacity 0.3s, visibility 0.3s;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
  }

  .c-posts__filter.is-active .c-posts__filter-list {
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }

  .c-posts__filter-button {
    width: 100%;
    text-align: left;
    color: $color-green-light;
    padding: 10px 20px;
  }

  .c-posts__filter-button:hover {
    color: $color-green;
  }

  .c-posts__filter-button:active {
    color: $color-green-dark;
  }
}

@include bp(520px) {
  .c-posts__items {
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    max-width: 636px;
  }

  .c-posts__item {
    margin-left: 0;
    width: calc(50% - 15px);
  }
}

@include bp(medium) {
  .c-posts__filter-sort {
    display: none;
  }

  .c-posts__filter-list {
    line-height: normal;
    text-align: center;
    color: $color-green-dark;
  }

  .c-posts__filter-button {
    margin: 5px 10px;
    padding: 5px;
  }

  .c-posts__filter {
    margin-bottom: 45px;
  }
}

@include bp(large) {
  .c-posts {
    margin-top: 100px;
    margin-bottom: 100px;
    max-width: 969px;
  }

  .c-posts[data-page='1'] .c-posts__item:nth-child(n+10) {
    display: none;
  }

  .c-posts__inner {
    width: 100%;
  }

  .c-posts__filter-list {
    font-size: 18px;
  }

  .c-posts__items {
    max-width: none;
    justify-content: flex-start;
    margin-left: -30px;
  }

  .c-posts__item {
    margin-left: 30px;
    width: calc(33.3333% - 30px);
  }
}
