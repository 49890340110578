/* ==========================================================================
   #GO BACK
   ========================================================================== */

.c-goback {
  margin-top: 40px;
  margin-bottom: 40px;
}

.c-goback--contact {
  margin-top: 180px;
  margin-bottom: 40px;
}

.c-goback__link {
  margin-top: 40px;
  display: inline-block;
  color: #80d6c6;
}

@include bp-between(medium, large) {
  .c-goback__separator {
    margin-left: -25px;
    margin-right: -25px;
  }
}

@include bp(large) {
  .c-goback--contact {
    margin-top: 200px;
  }

  .c-goback--contact .c-goback__separator {
    max-width: (802/1112) * 100%;
  }
}
