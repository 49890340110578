/* ==========================================================================
   #CONTAINER
   ========================================================================== */

.o-container {
  margin-right: auto;
  margin-left: auto;
}

@include bp-down(medium) {
  .o-container-offset-small {
    width: calc(100% - #{2*$offset}px);
  }
}

@include bp(medium) {
  .o-container-offset {
    width: calc(100% - #{2*$offset}px);
  }
}

@include bp($container-width * 1px) {
  .o-container {
    width: #{$container-width}px;
  }
}

@include bp-between(medium, $container-offset * 1px) {
  .o-container-offset-medium {
    width: calc(100% - #{2*$offset}px);
  }
}

@include bp-between(large, $container-offset * 1px) {
  .o-container-offset-large {
    width: calc(100% - #{2*$offset}px);
  }
}
