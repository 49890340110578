/* ==========================================================================
   #TEXT
   ========================================================================== */

.u-regular {
  font-weight: 400;
}

.u-bold {
  font-weight: 700;
}

.u-uppercase {
  text-transform: uppercase;
}

.u-center {
  text-align: center;
}

.u-left {
  text-align: left;
}

.u-right {
  text-align: right;
}

@each $name, $color in $link-colors {
  .u-#{$name} {
    color: $color;
  }
}
