/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  margin: 120px 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-footer__icon {
  display: block;
}

.c-footer__mail {
  margin-top: 14px;
}

.c-footer__social {
  margin-top: 6px;
  display: flex;
  margin-bottom: -18px;
}

.c-footer__social-link {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-footer__social-icon {
  width: 24px;
  height: 24px;
}

.c-footer__social-icon--twitter {
  width: 27px;
}

.c-footer__social-icon--mail {
  width: 27px;
  height: 21px;
}

@include bp(medium) {
  margin-top: 70px;
}
