/*
  Project: Elefint Redesign
  Author: Xfive
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3em;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 1em;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  font-style: normal;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
html {
  background: #fff;
  color: #484848;
  font-family: "azo-sans-web", sans-serif;
  font-size: 16px;
  line-height: 1.286;
  min-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

/* stylelint-disable-next-line selector-no-qualifying-type, rule-empty-line-before */
html.hide-mailchimp [id^='PopupSignupForm'] {
  display: none;
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
img {
  font-style: italic;
  max-width: 100%;
  vertical-align: middle;
}

/* ==========================================================================
   #INPUT
   ========================================================================== */
button, input, textarea, select {
  font-family: inherit;
  color: inherit;
  font-weight: inherit;
  background: transparent;
  border: none;
  padding: 0;
  border-radius: 0;
}

input, textarea, select {
  line-height: normal;
}

button {
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: currentColor;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: currentColor;
  text-decoration: none;
  transition: 0.3s color, box-shadow 0.3s;
  text-decoration-skip: ink;
}

a:hover {
  color: currentColor;
  text-decoration: none;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #SVG
   ========================================================================== */
svg {
  fill: currentColor;
  transition: fill 0.3s;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
.o-btn {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  padding: 0.875em 1.375em 0.9375em;
  transition: color 0.3s, background-color 0.3s;
  border-radius: 2px;
  text-decoration: none;
  font-weight: 700;
  line-height: 1;
}

/* Style variants
   ========================================================================== */
.o-btn--primary {
  background-color: #227a70;
  color: #fff;
}

.o-btn--primary:hover, .o-btn--primary:active {
  background-color: #4cd0b7;
  color: #fff;
}

/* Size variants
   ========================================================================== */
.o-btn--small {
  padding: 0.25rem 0.5rem;
}

.o-btn--large {
  padding: 0.75rem 1.5rem;
}

/* ==========================================================================
   #CONTAINER NARROW
   ========================================================================== */
.o-container-narrow {
  margin-right: auto;
  margin-left: auto;
  max-width: 800px;
}

@media (min-width: 800px) {
  .o-container-narrow .o-container-narrow__inner {
    margin-left: 35px;
    margin-right: auto;
    width: 676px;
  }
}

/* ==========================================================================
   #CONTAINER
   ========================================================================== */
.o-container {
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 767.98px) {
  .o-container-offset-small {
    width: calc(100% - 60px);
  }
}

@media (min-width: 768px) {
  .o-container-offset {
    width: calc(100% - 60px);
  }
}

@media (min-width: 1272px) {
  .o-container {
    width: 1272px;
  }
}

@media (min-width: 768px) and (max-width: 1331.98px) {
  .o-container-offset-medium {
    width: calc(100% - 60px);
  }
}

@media (min-width: 1024px) and (max-width: 1331.98px) {
  .o-container-offset-large {
    width: calc(100% - 60px);
  }
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
.o-h1,
.o-h2,
.o-h3 {
  font-weight: 700;
  line-height: 1;
}

.o-h1 {
  font-size: 66px;
}

.o-h2 {
  font-size: 50px;
}

.o-h3 {
  font-size: 38px;
}

@media (min-width: 768px) {
  .o-h1 {
    font-size: 136px;
  }
  .o-h2 {
    font-size: 65px;
  }
}

@media (min-width: 1024px) {
  .o-h1 {
    font-size: 196px;
  }
  .o-h2 {
    font-size: 65px;
  }
}

/* ==========================================================================
   #HR
   ========================================================================== */
.o-hr {
  display: block;
  border: none;
  border-top: 2px solid #ebecee;
  margin: 0;
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}

@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}

.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
.o-link-green-dark, .o-links-green-dark a {
  color: #227a70;
}

.o-link-green-dark:hover, .o-links-green-dark a:hover, .o-link-green-dark:active, .o-links-green-dark a:active {
  color: #4cd0b7;
}

.o-link-green-light, .o-links-green-light a {
  color: #4cd0b7;
}

.o-link-green-light:hover, .o-links-green-light a:hover {
  color: #37b19a;
}

.o-link-green-light:active, .o-links-green-light a:active {
  color: #227a70;
}

.o-link-grey-light, .o-links-grey-light a {
  color: #cbcbcb;
}

.o-link-grey-light:hover, .o-links-grey-light a:hover, .o-link-grey-light:active, .o-links-grey-light a:active {
  color: #4cd0b7;
}

.o-link-text, .o-links-text a {
  color: #4cd0b7;
}

.o-link-text:hover, .o-links-text a:hover {
  color: #37b19a;
}

.o-link-text:active, .o-links-text a:active {
  color: #f54812;
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #NO OUTSTANDING MARGINS
   ========================================================================== */
.o-no-edge-margins > *:first-child {
  margin-top: 0;
}

.o-no-edge-margins > *:last-child {
  margin-bottom: 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #TEXT
   ========================================================================== */
.o-text {
  font-size: 23px;
  line-height: 1.56522;
}

.o-text h1 {
  font-size: 2em;
}

.o-text h2 {
  font-size: 1.5em;
}

.o-text h3, .o-text h4, .o-text h5 {
  font-size: 1em;
}

.o-text blockquote {
  margin: 1em 30px;
  font-style: normal;
}

.o-text img {
  display: block;
  width: calc(100% + 60px);
  height: auto;
  margin: 0 -30px;
  max-width: none;
}

@media (min-width: 768px) {
  .o-text blockquote {
    margin-left: 70px;
    margin-right: 70px;
  }
  .o-text img {
    margin-left: -50px;
    margin-right: -50px;
    width: calc(100% + 100px);
  }
}

@media (min-width: 800px) {
  .o-text img {
    margin-left: -35px;
    margin-right: 0;
    width: 800px;
  }
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  width: calc(100% - 60px);
}

@media (min-width: 768px) {
  .o-wrapper {
    width: calc(100% - 100px);
  }
}

@media (min-width: 1200px) {
  .o-wrapper {
    width: calc(100% - 160px);
  }
}

/* ==========================================================================
   #CAROUSEL
   ========================================================================== */
.c-carousel {
  margin-top: 60px;
  margin-bottom: 60px;
}

.c-carousel__title {
  margin-bottom: 30px;
}

.c-carousel__text {
  line-height: 1.45455;
  font-size: 22px;
}

.c-carousel__list {
  list-style-type: none;
  margin: -15px 0 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.c-carousel__list.is-active .c-carousel__item:nth-child(n + 5) {
  display: block;
}

.c-carousel__item {
  width: calc(50% - 8px);
  margin-top: 45px;
  text-align: center;
}

.c-carousel__item:nth-child(n + 5) {
  display: none;
}

.c-carousel__item-image {
  width: 90px;
  height: 90px;
  display: inline-block;
  border-radius: 50%;
  margin-bottom: 10px;
}

.c-carousel__item-name {
  font-size: 20px;
  line-height: normal;
  margin-bottom: 4px;
}

.c-carousel__item-position {
  color: #1c1714;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4px;
}

.c-carousel__item-logo {
  max-width: 98px;
  max-height: 35px;
}

.c-carousel__item-logo-wrapper {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.c-carousel__more {
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.7px;
  line-height: 1.82;
  text-transform: uppercase;
  background-color: #4cd0b7;
  width: 100%;
  padding: 5px;
  margin-top: 35px;
  transition: background-color 0.4s;
}

.c-carousel__more:hover,
.c-carousel__more:active {
  background-color: #1a1a1a;
}

.c-carousel__nav {
  display: none;
}

@media (min-width: 768px) {
  .c-carousel__text {
    margin-top: 19px;
    font-size: 23px;
    max-width: 95.05988%;
  }
  .c-carousel__list {
    width: 100%;
    margin: 69px auto -40px;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;
  }
  .c-carousel__list-wrapper {
    position: relative;
  }
  .c-carousel__list-wrapper:after {
    content: '';
    display: block;
    position: absolute;
    left: -10px;
    right: -10px;
    bottom: -12px;
    height: 40px;
    background-color: #fff;
  }
  .c-carousel__item {
    display: block;
    width: 50%;
    flex-shrink: 0;
    flex-grow: 0;
    margin-top: 0;
    margin-bottom: 40px;
  }
  .c-carousel__item:nth-child(n + 5) {
    display: inline-block;
  }
  .c-carousel__item-image {
    width: 142px;
    height: 142px;
  }
  .c-carousel__item-name {
    margin-top: 20px;
  }
  .c-carousel__item-position {
    font-size: 16px;
  }
  .c-carousel__item-logo {
    max-width: 152px;
    max-height: 56px;
    width: auto;
    height: auto;
  }
  .c-carousel__item-logo-wrapper {
    margin-top: 15px;
    height: 56px;
  }
  .c-carousel__more {
    display: none;
  }
  .c-carousel__nav {
    display: block;
    width: 50px;
    text-align: center;
    position: absolute;
    top: 46px;
    height: 50px;
    color: #d7d7d7;
  }
  .c-carousel__nav--prev {
    right: 100%;
  }
  .c-carousel__nav--prev svg {
    transform: rotate(-180deg);
    transform-origin: 50% 50%;
  }
  .c-carousel__nav--next {
    left: 100%;
  }
  .c-carousel__nav svg {
    width: 26px;
    height: 50px;
    display: block;
    margin: 0 auto;
  }
}

@media (min-width: 1024px) {
  .c-carousel {
    margin-top: 75px;
    margin-bottom: 75px;
  }
  .c-carousel__item {
    width: 33.333%;
  }
  .c-carousel__text {
    line-height: 1.33333;
    font-size: 30px;
    max-width: 72.1223%;
  }
}

@media (min-width: 1200px) {
  .c-carousel__list {
    margin-top: 70px;
  }
  .c-carousel__item {
    width: 25%;
  }
}

/* ==========================================================================
   #CASE STUDIES
   ========================================================================== */
.c-case-studies__image-wrapper {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 62.5%;
  box-shadow: 0 2px 13px rgba(0, 0, 0, 0.152853);
  transition: box-shadow 0.3s;
}

a.c-case-studies__image-wrapper:hover,
a.c-case-studies__image-wrapper:active {
  box-shadow: none;
}

.c-case-studies__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
}

@media (max-width: 1023.98px) {
  .c-case-studies__client,
  .c-case-studies__title,
  .c-case-studies__text,
  .c-case-studies__button {
    display: none;
  }
}

@media (min-width: 1024px) {
  .c-case-studies {
    margin-bottom: 82px;
  }
  .c-case-studies__items {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -82px;
  }
  .c-case-studies__item {
    width: 50%;
    margin-bottom: 82px;
  }
  .c-case-studies__client {
    font-size: 15px;
    margin-top: 24px;
    line-height: normal;
  }
  .c-case-studies__title {
    font-size: 22px;
    margin-top: 17px;
    margin-bottom: 0;
    line-height: 1.36364;
    padding-right: 21.22642%;
  }
  .c-case-studies__text {
    font-size: 18px;
    line-height: 1.5;
    margin-top: 2px;
    padding-right: 21.22642%;
  }
  .c-case-studies__button {
    margin-top: 23px;
  }
}

/* ==========================================================================
   #COLUMNS
   ========================================================================== */
.c-columns {
  margin-top: 60px;
  margin-bottom: 60px;
}

.c-columns__title {
  margin-bottom: 60px;
}

.c-columns__media {
  margin-bottom: 15px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.c-columns__icon {
  display: block;
  width: auto;
  height: auto;
}

.c-columns--big .c-columns__text {
  font-size: 18px;
  line-height: 1.5;
}

.c-columns__subtitle {
  font-size: 24px;
  margin-bottom: 13px;
  line-height: 1;
}

.c-columns--big .c-columns__subtitle {
  font-size: 39px;
}

.c-columns__column {
  margin-bottom: 60px;
}

@media (min-width: 540px) {
  .c-columns__items {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-left: -8.98204%;
    margin-bottom: -60px;
  }
  .c-columns__column {
    width: 25.17007%;
    margin-left: 8.16327%;
  }
}

@media (min-width: 1024px) {
  .c-columns--wide .c-columns__items {
    min-width: 703px;
  }
  .c-columns__text--big {
    font-size: 18px;
    line-height: 1.5;
  }
}

@media (min-width: 1200px) {
  .c-columns--wide .c-columns__items {
    width: 1080px;
    margin-left: -120px;
  }
  .c-columns--wide .c-columns__column {
    width: 240px;
    margin-left: 120px;
  }
}

/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */
.c-comment-form {
  margin-top: 2em;
}

.c-comment-form label {
  display: block;
}

.c-comment-form textarea {
  max-width: 100%;
}

.comment-reply-title {
  margin-bottom: 0.25em;
}

/* ==========================================================================
   #COMMENT
   ========================================================================== */
.c-comment {
  margin-bottom: 1em;
}

.c-comment__author {
  font-size: 1rem;
  margin-bottom: 0.25em;
}

.c-comment__moderation {
  font-style: italic;
  margin-bottom: 0.5em;
}

/* ==========================================================================
   #DESIGN
   ========================================================================== */
.c-design__inner {
  position: relative;
  background-color: #ff7142;
  text-align: center;
  padding: 180px 0 335px;
}

.c-design__content {
  max-width: 486px;
}

.c-design__title {
  line-height: 1.26316;
  margin-bottom: 0;
}

.c-design__text {
  font-size: 18px;
  line-height: 1.5;
  margin-top: 9px;
}

.c-design__button {
  margin-top: 30px;
}

.c-design__target {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 122px;
  height: 61px;
}

.c-design__flower {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 109px;
  height: 227px;
}

.c-design__square {
  display: none;
}

@media (min-width: 600px) {
  .c-design {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .c-design__inner {
    padding: 78px 70px;
    margin-left: 30px;
    margin-right: 30px;
  }
  .c-design__title {
    font-size: 32px;
  }
  .c-design__target {
    width: 90px;
    height: 45px;
  }
  .c-design__flower {
    margin: 0;
  }
}

@media (max-width: 1023.98px) {
  .c-design__oval,
  .c-design__shape-2,
  .c-design__shape-1 {
    display: none;
  }
}

@media (min-width: 1024px) {
  .c-design__flower {
    left: auto;
    right: 50%;
    margin-right: 340px;
  }
  .c-design__oval {
    position: absolute;
    right: -30px;
    bottom: -30px;
  }
  .c-design__shape-1 {
    position: absolute;
    right: 60px;
    top: -10px;
  }
  .c-design__shape-2 {
    position: absolute;
    left: 60px;
    top: 45px;
    transform: rotate(135deg);
  }
}

@media (min-width: 1110px) {
  .c-design__square {
    display: block;
    position: absolute;
    left: auto;
    bottom: 0;
    right: 50%;
    margin-right: 450px;
  }
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  margin: 120px 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-footer__icon {
  display: block;
}

.c-footer__mail {
  margin-top: 14px;
}

.c-footer__social {
  margin-top: 6px;
  display: flex;
  margin-bottom: -18px;
}

.c-footer__social-link {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-footer__social-icon {
  width: 24px;
  height: 24px;
}

.c-footer__social-icon--twitter {
  width: 27px;
}

.c-footer__social-icon--mail {
  width: 27px;
  height: 21px;
}

@media (min-width: 768px) {
  margin-top: 70px;
}

/* ==========================================================================
   #GO BACK
   ========================================================================== */
.c-goback {
  margin-top: 40px;
  margin-bottom: 40px;
}

.c-goback--contact {
  margin-top: 180px;
  margin-bottom: 40px;
}

.c-goback__link {
  margin-top: 40px;
  display: inline-block;
  color: #80d6c6;
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .c-goback__separator {
    margin-left: -25px;
    margin-right: -25px;
  }
}

@media (min-width: 1024px) {
  .c-goback--contact {
    margin-top: 200px;
  }
  .c-goback--contact .c-goback__separator {
    max-width: 72.1223%;
  }
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  height: 80px;
}

.c-header__wrapper {
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #fff;
}

.c-header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

.c-header__logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-header__logo:active, .c-header__logo:hover {
  color: #fd6f2e;
}

.c-header__logo-icon {
  display: block;
}

.c-header__logo-text {
  display: none;
}

.c-header__menu {
  margin-bottom: 0;
}

.c-header__menu-item {
  margin-bottom: 0;
}

.single-work .c-header__menu-item.is-work,
.single-post .c-header__menu-item.is-labs, .c-header__menu-item.is-active, .c-header__menu-item:active, .c-header__menu-item:hover {
  color: #fd6f2e;
}

.c-header__burger:active {
  color: #fd6f2e;
}

.c-header__burger-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1023.98px) {
  .c-header__wrapper {
    transition: background-color 0.3s;
  }
  .c-header__menu {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: calc(100vh - 100%);
    background-color: #227a70;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: normal;
    font-weight: 700;
    font-size: 56px;
    color: #fff;
    transition: opacity 0.3s, visibility 0.3s;
    opacity: 0;
    visibility: hidden;
  }
  .c-header__menu-item:first-child {
    margin-top: auto;
  }
  .c-header__menu-item:last-child {
    margin-bottom: auto;
  }
  .is-menu-open .c-header__wrapper {
    color: #fff;
    background-color: #227a70;
  }
  .is-menu-open .c-header__menu {
    opacity: 1;
    visibility: visible;
    -webkit-overflow-scrolling: touch;
  }
}

@media (min-width: 1024px) {
  .c-header__menu-item {
    margin-left: 45px;
  }
  .c-header__logo-icon {
    display: none;
  }
  .c-header__logo-text {
    display: block;
    line-height: normal;
    font-size: 26px;
  }
  .c-header__menu {
    line-height: normal;
    font-size: 18px;
    display: flex;
  }
  .c-header__burger {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .c-header__container {
    padding: 0 30px;
  }
}

/* ==========================================================================
   #TEXT
   ========================================================================== */
.c-headline {
  margin-top: 80px;
  margin-bottom: 80px;
}

.c-headline--image {
  margin-top: 35px;
}

.c-headline__title {
  margin-bottom: 17px;
}

.c-headline__media {
  display: block;
}

.c-headline__headline {
  line-height: 1.45455;
  font-size: 22px;
}

.c-headline__image {
  display: block;
  width: 100%;
  height: auto;
  box-shadow: 0 2px 13px rgba(0, 0, 0, 0.152853);
  max-width: 674px;
  transition: box-shadow 0.3s;
}

/* stylelint-disable-next-line selector-no-qualifying-type, rule-empty-line-before */
a.c-headline__media:hover .c-headline__image,
a.c-headline__media:active .c-headline__image {
  /* stylelint-disable-line selector-no-qualifying-type */
  box-shadow: none;
}

.c-headline__cta {
  margin-top: 30px;
}

.c-headline__text {
  margin-top: 35px;
}

@media (max-width: 767.98px) {
  .c-headline__media {
    margin-left: -13px;
    margin-right: -13px;
  }
  .c-headline--image .c-headline__content {
    margin-top: 46px;
  }
}

@media (min-width: 768px) {
  .c-headline {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .c-headline--image {
    margin-top: 75px;
    margin-bottom: 75px;
  }
  .c-headline__content {
    max-width: 95.05988%;
  }
  .c-headline--image .c-headline__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .c-headline--image .c-headline__media {
    width: 57.31523%;
  }
  .c-headline--image .c-headline__content {
    width: 38.91403%;
  }
  .c-headline__title {
    margin-bottom: 23px;
  }
  .c-headline__headline {
    line-height: 1.3913;
    font-size: 23px;
  }
  .c-headline__text {
    margin-top: 40px;
  }
}

@media (min-width: 1024px) {
  .c-headline__content {
    max-width: 72.1223%;
  }
  .c-headline--image .c-headline__media {
    width: 60.61151%;
  }
  .c-headline--image .c-headline__content {
    width: 37.58993%;
  }
  .c-headline__headline {
    line-height: 1.33333;
    font-size: 30px;
  }
  .c-headline__cta {
    margin-top: 40px;
  }
}

@media (min-width: 1200px) {
  .c-headline--image .c-headline__media {
    margin-right: -27px;
  }
}

/* ==========================================================================
   #HERO
   ========================================================================== */
.c-hero {
  margin-top: 70px;
  margin-bottom: 70px;
}

.c-hero__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-hero__header {
  font-size: 14px;
  margin-bottom: 44px;
}

.c-hero__title {
  margin-bottom: 30px;
}

.c-hero__headline {
  line-height: 1.3913;
  font-size: 23px;
  margin: 0 auto 30px;
}

.c-hero__footer {
  margin-bottom: 15px;
}

.c-hero__date {
  font-size: 14px;
  margin-bottom: 20px;
}

.c-hero__social {
  margin: auto auto 0;
}

.c-hero__social .c-social__inner {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

@media (min-width: 420px) {
  .c-hero__social .c-social__more {
    top: 100%;
  }
}

@media (max-width: 767.98px) {
  .c-hero {
    display: flex;
  }
  .c-hero:before {
    content: '';
    width: 0;
    display: block;
    height: calc(100vh - 220px);
    flex-grow: 0;
  }
}

@media (max-width: 1023.98px) {
  .c-hero__title br {
    display: none;
  }
}

@media (min-width: 420px) {
  .c-hero__social .c-social__more {
    left: -88px;
  }
}

@media (min-width: 768px) {
  .c-hero {
    margin-top: 130px;
    margin-bottom: 75px;
  }
  .c-hero__inner {
    padding: 0;
    display: block;
    max-width: 908px;
  }
  .c-hero__headline {
    width: 86.82635%;
  }
  .c-hero__social {
    margin-top: 65px;
  }
  .c-hero__header {
    margin-bottom: 50px;
  }
  .c-hero__title {
    margin-bottom: 40px;
  }
}

@media (min-width: 1024px) {
  .c-hero__headline {
    width: 74.77876%;
    font-size: 30px;
    line-height: 1.33333;
  }
}

@media (min-width: 1200px) {
  .c-hero {
    margin-bottom: 45px;
  }
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
.c-images {
  margin-top: 20px;
  margin-bottom: 20px;
}

.c-images__inner {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -20px;
  align-items: flex-start;
  justify-content: flex-start;
}

.c-images__image {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .c-images {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .c-images__inner {
    margin-bottom: -30px;
  }
  .c-images__image {
    margin-bottom: 30px;
  }
  .c-images__image--small {
    width: calc(100% - 60px);
    margin-left: 30px;
  }
}

@media (min-width: 1024px) {
  .c-images__image--small {
    width: calc(50% - 45px);
  }
}

@media (min-width: 1332px) {
  .c-images {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .c-images__inner {
    margin-left: -40px;
    margin-bottom: -40px;
  }
  .c-images__image {
    margin-bottom: 40px;
    margin-left: 40px;
    width: calc(100% - 40px);
  }
  .c-images__image--small {
    width: calc(50% - 40px);
  }
}

/* ==========================================================================
   #LOGOS
   ========================================================================== */
.c-logos {
  margin-top: 50px;
  margin-bottom: 50px;
}

.c-logos__title {
  margin-bottom: 10px;
}

.c-logos__headline {
  line-height: 1.45455;
  font-size: 22px;
}

.c-logos__headline a {
  text-decoration: underline;
}

.c-logos__image {
  display: block;
  width: 202px;
  height: auto;
  max-height: 95px;
  margin: auto;
}

.c-logos__items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
}

.c-logos__item {
  width: calc(50% - 20px);
  text-align: center;
  margin-top: 45px;
  margin-left: 20px;
}

@media (min-width: 768px) {
  .c-logos {
    margin-top: 75px;
    margin-bottom: 75px;
  }
  .c-logos__title {
    margin-bottom: 13px;
  }
  .c-logos__headline {
    line-height: 1.3913;
    font-size: 23px;
  }
}

@media (min-width: 1024px) {
  .c-logos {
    margin-top: 90px;
    margin-bottom: 90px;
  }
  .c-logos__headline {
    line-height: 1.33333;
    font-size: 30px;
  }
  .c-logos__title {
    margin-bottom: 15px;
  }
}

@media (min-width: 540px) {
  .c-logos__item {
    width: calc(33.33% - 20px);
  }
}

@media (min-width: 768px) {
  .c-logos__items {
    margin-left: -30px;
  }
  .c-logos__item {
    width: calc(25% - 30px);
    margin-left: 30px;
    margin-top: 50px;
  }
}

@media (min-width: 1024px) {
  .c-logos__items {
    margin-left: 0;
  }
  .c-logos__item {
    margin-top: 60px;
    margin-left: 0;
    width: 25%;
  }
}

/* ==========================================================================
   #POSTER
   ========================================================================== */
.c-poster {
  padding: 100px 0;
}

.c-poster__title {
  font-weight: 700;
  line-height: 1;
  font-size: 48px;
  margin-bottom: 0;
}

.c-poster__text {
  margin-top: 13px;
  line-height: 1.45455;
  font-size: 18px;
}

.c-poster__button {
  margin-top: 22px;
}

.c-poster__media {
  display: none;
}

@media (min-width: 400px) {
  .c-poster__title {
    font-size: 68px;
  }
  .c-poster__text {
    font-size: 22px;
  }
}

@media (min-width: 768px) {
  .c-poster {
    margin-top: 30px;
    margin-bottom: 30px;
    overflow: hidden;
  }
  .c-poster__content {
    width: 85%;
  }
  .c-poster__title {
    font-size: 78px;
  }
  .c-poster__text {
    width: 90%;
    font-size: 23px;
    margin-top: 17px;
  }
  .c-poster__media {
    display: block;
    margin-right: -50px;
    margin-top: -140px;
    margin-bottom: -65px;
  }
  .c-poster__image {
    display: block;
    width: 424px;
    height: auto;
    margin-right: -222px;
    margin-left: auto;
  }
}

@media (min-width: 1024px) {
  .c-poster__title {
    font-size: 108px;
  }
  .c-poster__text {
    margin-top: 29px;
    font-size: 24px;
    width: 90.50445%;
  }
  .c-poster__button {
    margin-top: 24px;
  }
  .c-poster__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .c-poster__content {
    width: 60.17699%;
    flex-shrink: 0;
  }
  .c-poster__media {
    margin: 0 0 0 25px;
    flex-shrink: 1;
    flex-grow: 1;
  }
  .c-poster__image {
    max-width: none;
    margin: 100px auto -40px 0;
  }
}

@media (min-width: 1200px) {
  .c-poster {
    padding: 160px 0;
  }
  .c-poster__inner {
    width: calc(100% - 140px);
  }
}

@media (min-width: 1332px) {
  .c-poster {
    margin: 40px auto;
  }
}

/* ==========================================================================
   #POSTS
   ========================================================================== */
.c-posts {
  margin-top: 80px;
  margin-bottom: 80px;
}

.c-posts__headline {
  line-height: 1.45455;
  font-size: 22px;
  margin-bottom: 50px;
}

.c-posts__filter-sort {
  background-color: #fae9e8;
  width: 100%;
  display: block;
}

.c-posts__filter-sort-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 20px;
  line-height: 1;
}

.c-posts__filter-chevron {
  width: 22px;
  height: 9px;
  display: block;
  filter: drop-shadow(0 4px 2px rgba(0, 0, 0, 0.25));
  transition: filter 0.3s, fill 0.3s;
}

.c-posts__filter-sort:hover .c-posts__filter-chevron {
  filter: none;
}

.c-posts__filter-button {
  transition: color 0.3s;
}

.c-posts__filter-button.is-active {
  color: #f54812;
}

.c-posts__items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  margin-top: -30px;
  position: relative;
}

.c-posts__items:after {
  display: block;
  content: '';
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -10px;
  right: -10px;
  top: -10px;
  bottom: -10px;
  background-color: rgba(255, 255, 255, 0.85);
}

.c-posts.is-loading .c-posts__items:after {
  visibility: visible;
  opacity: 1;
}

.c-posts__item {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.17);
  margin-top: 30px;
  width: 100%;
  max-width: 303px;
}

.c-posts__media {
  display: block;
  position: relative;
  padding-top: 44.55446%;
}

.c-posts__media:empty {
  display: none;
}

.c-posts__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-posts__content {
  padding: 30px 20px 40px;
}

.c-posts__categories {
  font-size: 14px;
  line-height: normal;
  margin-bottom: 13px;
}

.c-posts__title {
  margin-bottom: 0;
  line-height: 1.4;
  font-size: 20px;
}

.c-posts__footer {
  text-align: center;
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.c-posts__filter {
  margin-bottom: 45px;
}

.c-posts.no-more-button .c-posts__footer {
  display: none;
}

@media (max-width: 767.98px) {
  .c-posts__filter {
    position: relative;
    max-width: 420px;
  }
  .c-posts__filter-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    transition: opacity 0.3s, visibility 0.3s;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
  }
  .c-posts__filter.is-active .c-posts__filter-list {
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }
  .c-posts__filter-button {
    width: 100%;
    text-align: left;
    color: #4cd0b7;
    padding: 10px 20px;
  }
  .c-posts__filter-button:hover {
    color: #37b19a;
  }
  .c-posts__filter-button:active {
    color: #227a70;
  }
}

@media (min-width: 520px) {
  .c-posts__items {
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    max-width: 636px;
  }
  .c-posts__item {
    margin-left: 0;
    width: calc(50% - 15px);
  }
}

@media (min-width: 768px) {
  .c-posts__filter-sort {
    display: none;
  }
  .c-posts__filter-list {
    line-height: normal;
    text-align: center;
    color: #227a70;
  }
  .c-posts__filter-button {
    margin: 5px 10px;
    padding: 5px;
  }
  .c-posts__filter {
    margin-bottom: 45px;
  }
}

@media (min-width: 1024px) {
  .c-posts {
    margin-top: 100px;
    margin-bottom: 100px;
    max-width: 969px;
  }
  .c-posts[data-page='1'] .c-posts__item:nth-child(n+10) {
    display: none;
  }
  .c-posts__inner {
    width: 100%;
  }
  .c-posts__filter-list {
    font-size: 18px;
  }
  .c-posts__items {
    max-width: none;
    justify-content: flex-start;
    margin-left: -30px;
  }
  .c-posts__item {
    margin-left: 30px;
    width: calc(33.3333% - 30px);
  }
}

/* ==========================================================================
   #RELATED CONTENT
   ========================================================================== */
.c-related-content {
  margin-top: 60px;
  margin-bottom: 40px;
}

.c-related-content__separator {
  margin-bottom: 40px;
}

.c-related-content__header {
  margin-bottom: 30px;
}

.c-related-content__link {
  line-height: 1.2;
  font-size: 30px;
  margin-bottom: 40px;
}

/* ==========================================================================
   #SEPARATOR
   ========================================================================== */
.c-separator {
  margin-top: 80px;
  margin-bottom: 80px;
  border: none;
  border-top: 2px solid #f8f8f8;
}

@media (min-width: 768px) {
  .c-separator {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

/* ==========================================================================
   #SLIDER
   ========================================================================== */
.c-slider {
  text-align: center;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  line-height: normal;
}

.c-slider:before {
  content: '';
  display: block;
  width: 0;
  flex-grow: 0;
  height: calc(100vh - 80px);
  max-height: 720px;
}

.c-slider__slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 0 80px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}

.c-slider__slide:not(.is-active) {
  height: 100%;
}

.c-slider__slide.is-active {
  z-index: 1;
  position: relative;
  opacity: 1;
  visibility: visible;
}

.c-slider__bg-link,
.c-slider__bg {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.c-slider__header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.c-slider__title {
  margin-bottom: 0;
  position: relative;
  max-width: 100%;
  padding-top: 60px;
}

.c-slider__title--big {
  padding-top: 0;
}

.c-slider__title-overlay {
  display: block;
  position: absolute;
  width: 150%;
  height: auto;
  left: 50%;
  bottom: 0;
  max-width: 100vw;
  transform: translate(-50%, 50%);
}

.c-slider__subtitle {
  line-height: normal;
  margin-top: 12px;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 0;
}

.c-slider__nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 44px;
  bottom: 120px;
  z-index: 2;
}

.c-slider__dot {
  padding: 6px;
}

.c-slider__dot.is-active > *,
.c-slider__dot:active > *,
.c-slider__dot:hover > * {
  background-color: #fd6f2e;
}

.c-slider__dot > * {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transition: background-color 0.3s;
  text-indent: -200vw;
  overflow: hidden;
  font-size: 0.1px;
  line-height: 0.1px;
  background-color: #cadcd9;
}

.c-slider__icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 50%;
}

.c-slider__footer {
  width: 100%;
  padding: 60px 47px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
  margin-bottom: auto;
}

.c-slider__buttons {
  margin-left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.c-slider__button + .c-slider__button {
  margin-top: 19px;
}

.c-slider__play {
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
  line-height: 1;
  font-size: 18px;
}

.c-slider__play .c-slider__icon {
  margin-right: 16px;
  vertical-align: -40%;
}

.c-slider__play .c-slider__icon-image {
  margin-left: 10px;
}

.c-slider__video {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #000;
  transition: visibility 0.3s, opacity 0.3s;
  opacity: 0;
  visibility: hidden;
}

.c-slider__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-slider__video.is-active {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 399.98px) {
  .c-slider__title--big {
    font-size: 110px;
  }
}

@media (min-width: 400px) and (max-width: 767.98px) {
  .c-slider__title--big {
    font-size: 136px;
  }
}

@media (min-width: 768px) {
  .c-slider:before {
    max-height: 820px;
    min-height: 550px;
  }
  .c-slider__slide {
    padding: 70px 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .c-slider__title {
    padding-top: 0;
  }
  .c-slider__nav {
    right: 48px;
    bottom: 62px;
  }
  .c-slider__header {
    margin-top: -120px;
  }
  .c-slider__footer {
    position: absolute;
    bottom: 70px;
    left: 0;
    right: 0;
    padding: 0 55px;
  }
  .c-slider__play {
    margin-top: 18px;
    margin-bottom: 18px;
  }
  .c-slider__title-overlay {
    min-width: 540px;
  }
  .c-slider__button + .c-slider__button {
    margin-top: 23px;
  }
}

@media (min-width: 1024px) {
  .c-slider__slide {
    padding: 60px 0;
  }
  .c-slider__nav {
    right: 30px;
  }
  .c-slider__header {
    margin-top: -180px;
  }
  .c-slider__footer {
    bottom: 60px;
    padding: 0 65px;
  }
  .c-slider__play {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .c-slider__title-overlay {
    min-width: 838px;
  }
}

/* ==========================================================================
   #SOCIAL
   ========================================================================== */
.c-social__title {
  font-size: 18px;
  margin-bottom: 20px;
  width: 100%;
}

.c-social__inner {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.c-social__link {
  width: 34px;
  height: 34px;
  background-color: #efeeee;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #484848;
  transition: background-color 0.3s, color 0.3s;
  margin-right: 10px;
  margin-top: 10px;
}

.c-social__link.is-active, .c-social__link:hover, .c-social__link:active {
  background-color: #484848;
  color: #efeeee;
}

.c-social__links {
  display: flex;
  margin-right: -10px;
  margin-top: -10px;
  position: relative;
}

.c-social__more {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  left: 0;
  top: 100%;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 0;
  visibility: hidden;
  width: 176px;
}

.c-social__more.is-active {
  opacity: 1;
  visibility: visible;
}

.c-social__more-trigger .c-social__icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 420px) {
  .c-social__more {
    width: 352px;
  }
}

@media (min-width: 600px) {
  .c-social__more {
    top: 0;
    left: 100%;
  }
}

/* ==========================================================================
   #TEAM
   ========================================================================== */
.c-team {
  padding: 120px 0 180px;
  margin-top: 80px;
  margin-bottom: 80px;
  min-height: 1220px;
  position: relative;
}

.c-team:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../assets/images/bg-the-herd-pattern.svg") center top;
  background-size: auto 610px;
}

.c-team__title {
  margin-bottom: 30px;
}

.c-team__inner {
  position: relative;
}

.c-team__headline {
  line-height: 1.45455;
  font-size: 22px;
  margin-bottom: 40px;
}

.c-team__members {
  margin-bottom: -50px;
}

.c-team__member {
  margin-bottom: 50px;
}

.c-team__image {
  width: 202px;
  height: 202px;
  display: block;
  margin-bottom: 20px;
  border-radius: 50%;
}

.c-team__name {
  font-size: 28px;
  line-height: normal;
  margin-bottom: 6px;
}

.c-team__position {
  font-size: 18px;
  line-height: normal;
  margin-bottom: 4px;
}

.c-team__link {
  font-size: 18px;
  line-height: normal;
}

.c-team__main-link {
  position: absolute;
  width: 47px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: calc(50% - 98px);
  top: 106px;
}

.c-team__main-link-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-team__main-link-icon {
  width: 18px;
  height: 18px;
  display: block;
  position: relative;
  fill: #fff;
  margin-left: -4px;
  margin-top: -4px;
}

@media (min-width: 640px) {
  .c-team {
    padding-top: 80px;
    padding-bottom: 100px;
    min-height: 862px;
    margin-bottom: 110px;
  }
  .c-team:before {
    background-size: auto 862px;
  }
  .c-team__member {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 40px;
  }
  .c-team__image {
    margin-bottom: 0;
    flex-shrink: 0;
    flex-grow: 0;
  }
  .c-team__member-info {
    margin-left: 24px;
  }
  .c-team__headline {
    width: 77.84431%;
    font-size: 27px;
    margin-bottom: 70px;
    max-width: 520px;
  }
  .c-team__title {
    margin-bottom: 23px;
  }
  .c-team__main-link {
    width: 62px;
    height: 78px;
    right: calc(50% - 262px);
    top: 133px;
  }
}

@media (min-width: 1024px) {
  .c-team {
    margin-top: 110px;
    margin-bottom: 110px;
    padding-top: 140px;
    padding-bottom: 240px;
  }
  .c-team:before {
    background-repeat: no-repeat;
  }
  .c-team__headline {
    font-size: 30px;
    margin-bottom: 122px;
  }
  .c-team__members {
    margin-top: -40px;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
  }
  .c-team__member {
    margin-bottom: 0;
    margin-top: 40px;
    width: 50%;
    padding-right: 20px;
  }
  .c-team__main-link {
    width: 78px;
    height: 98px;
    right: calc(50% - 262px);
    top: 133px;
  }
  .c-team__main-link-icon {
    width: 22px;
    height: 22px;
    margin-left: -6px;
    margin-top: -6px;
  }
}

/* ==========================================================================
   #TESTIMONIALS
   ========================================================================== */
.c-testimonials {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 21'%3E %3Cpath d='M4 2c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2z' fill-rule='evenodd' clip-rule='evenodd' fill='%23d7f2ed'/%3E %3C/svg%3E") repeat left top;
  background-size: 21px 21px;
  padding: 80px 0;
}

.c-testimonials__items {
  position: relative;
}

.c-testimonials__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 0;
  visibility: hidden;
}

.c-testimonials__item.is-active {
  position: relative;
  opacity: 1;
  visibility: visible;
}

.c-testimonials__title {
  margin-bottom: 70px;
}

.c-testimonials__media {
  margin-bottom: 30px;
}

.c-testimonials__image {
  display: block;
  width: 74px;
  height: 74px;
  border-radius: 50%;
}

.c-testimonials__quote {
  line-height: 1.45455;
  font-size: 22px;
  margin-bottom: 20px;
}

.c-testimonials__author {
  color: #000;
  line-height: 1.5;
  font-style: normal;
}

.c-testimonials__position {
  color: #999ea8;
  line-height: 1.5;
}

.c-testimonials__nav {
  margin-top: 41px;
  display: flex;
  justify-content: center;
  margin-bottom: -9px;
}

.c-testimonials__dot {
  padding: 9px;
}

.c-testimonials__dot-number {
  font-size: 0.1px;
  line-height: 0.1px;
  position: relative;
  text-indent: -200vw;
  width: 13px;
  height: 13px;
  display: block;
  border-radius: 50%;
  background-color: #cadcd9;
  transition: background-color 0.3s;
}

.c-testimonials__dot.is-active .c-testimonials__dot-number,
.c-testimonials__dot:hover .c-testimonials__dot-number {
  background-color: #fd6f2e;
}

@media (min-width: 768px) {
  .c-testimonials {
    margin-top: 70px;
    margin-bottom: 70px;
  }
  .c-testimonials__items {
    width: 87.27545%;
  }
  .c-testimonials__item {
    display: flex;
  }
  .c-testimonials__quote {
    margin-bottom: 30px;
    line-height: 1.33333;
    font-size: 27px;
  }
  .c-testimonials__media {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 30px;
  }
}

@media (min-width: 1024px) {
  .c-testimonials {
    padding: 120px 0;
  }
  .c-testimonials__title {
    margin-bottom: 75px;
  }
  .c-testimonials__items {
    width: 672px;
    margin-left: auto;
    margin-right: auto;
  }
  .c-testimonials__nav {
    width: 672px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 103px;
    justify-content: flex-start;
  }
}

/* ==========================================================================
   #TEXT
   ========================================================================== */
.c-text {
  margin-top: 50px;
  margin-bottom: 50px;
}

@media (min-width: 768px) {
  .c-text {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

/* ==========================================================================
   #WORKS
   ========================================================================== */
.c-works {
  background-color: #f8f8f8;
  padding: 25px 0 110px;
  text-align: center;
}

.c-works__items {
  text-align: left;
}

.c-works__item {
  margin-bottom: 90px;
}

.c-works__media {
  display: block;
  box-shadow: 0 2px 13px rgba(0, 0, 0, 0.152853);
  margin-left: -13px;
  margin-right: -13px;
  margin-bottom: 47px;
  transition: box-shadow 0.3s;
}

.c-works__media:hover, .c-works__media:active {
  box-shadow: none;
}

.c-works__image {
  display: block;
  width: 100%;
  height: auto;
}

.c-works__title {
  line-height: 1.13636;
  font-size: 22px;
  margin-bottom: 0;
}

.c-works__client {
  margin-top: 10px;
  font-size: 14px;
}

.c-works__categories {
  font-size: 14px;
  margin-top: 15px;
}

.c-works__more {
  margin-top: 90px;
}

.c-works.no-more-button .c-works__more {
  display: none;
}

@media (min-width: 768px) {
  .c-works {
    padding-top: 45px;
    padding-bottom: 90px;
  }
  .c-works__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
  }
  .c-works__item:nth-child(even) {
    flex-direction: row-reverse;
  }
  .c-works__media {
    margin-bottom: 0;
    margin-right: 0;
    margin-left: -10px;
    width: 56.88623%;
  }
  .c-works__item:nth-child(even) .c-works__media {
    margin-right: -10px;
    margin-left: 0;
  }
  .c-works__content {
    width: 39.37126%;
  }
  .c-works__more {
    margin-top: 90px;
  }
  .c-works__client {
    margin-top: 15px;
  }
}

@media (min-width: 1200px) {
  .c-works {
    padding-top: 55px;
    padding-bottom: 100px;
  }
  .c-works__item {
    margin-bottom: 70px;
  }
  .c-works__media {
    width: 60.61151%;
    margin-left: -40px;
  }
  .c-works__item:nth-child(even) .c-works__media {
    margin-right: -40px;
  }
  .c-works__content {
    width: 37.76978%;
  }
  .c-works__title {
    line-height: 1.0625;
    font-size: 32px;
  }
  .c-works__client {
    font-size: 16px;
    margin-top: 18px;
  }
  .c-works__more {
    margin-top: 90px;
  }
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #FIT
   ========================================================================== */
.u-fit-cover {
  object-fit: cover;
}

.u-fit-contain {
  object-fit: contain;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}

@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

/* ==========================================================================
   #TEXT
   ========================================================================== */
.u-regular {
  font-weight: 400;
}

.u-bold {
  font-weight: 700;
}

.u-uppercase {
  text-transform: uppercase;
}

.u-center {
  text-align: center;
}

.u-left {
  text-align: left;
}

.u-right {
  text-align: right;
}

.u-orange {
  color: #f54812;
}

.u-orange-light {
  color: #fd6f2e;
}

.u-green {
  color: #37b19a;
}

.u-green-light {
  color: #4cd0b7;
}

.u-green-dark {
  color: #227a70;
}

.u-white {
  color: #fff;
}
