/* ==========================================================================
   #BUTTONS
   ========================================================================== */

.o-btn {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  padding: (14/16) + em (22/16) + em (15/16) + em;
  transition: color 0.3s, background-color 0.3s;
  border-radius: 2px;
  text-decoration: none;
  font-weight: 700;
  line-height: 1;
}

/* Style variants
   ========================================================================== */

.o-btn--primary {
  background-color: $color-green-dark;
  color: $color-white;

  &:hover,
  &:active {
    background-color: $color-green-light;
    color: $color-white;
  }
}

/* Size variants
   ========================================================================== */

.o-btn--small {
  padding: 0.25rem 0.5rem;
}

.o-btn--large {
  padding: 0.75rem 1.5rem;
}
