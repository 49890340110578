/* ==========================================================================
   #TEXT
   ========================================================================== */

.c-headline {
  margin-top: 80px;
  margin-bottom: 80px;

  &--image {
    margin-top: 35px;
  }
}

.c-headline__title {
  margin-bottom: 17px;
}

.c-headline__media {
  display: block;
}

.c-headline__headline {
  line-height: (32/22);
  font-size: 22px;
}

.c-headline__image {
  display: block;
  width: 100%;
  height: auto;
  box-shadow: 0 2px 13px rgba(0, 0, 0, 0.152853);
  max-width: 674px;
  transition: box-shadow 0.3s;
}

/* stylelint-disable-next-line selector-no-qualifying-type, rule-empty-line-before */
a.c-headline__media:hover .c-headline__image,
a.c-headline__media:active .c-headline__image { /* stylelint-disable-line selector-no-qualifying-type */
  box-shadow: none;
}

.c-headline__cta {
  margin-top: 30px;
}

.c-headline__text {
  margin-top: 35px;
}

@include bp-down(medium) {
  .c-headline__media {
    margin-left: -13px;
    margin-right: -13px;
  }

  .c-headline--image .c-headline__content {
    margin-top: 46px;
  }
}

@include bp(medium) {
  .c-headline {
    margin-top: 100px;
    margin-bottom: 100px;

    &--image {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  .c-headline__content {
    max-width: (635/668) * 100%;
  }

  .c-headline--image .c-headline__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .c-headline--image .c-headline__media {
    width: (380/663) * 100%;
  }

  .c-headline--image .c-headline__content {
    width: (258/663) * 100%;
  }

  .c-headline__title {
    margin-bottom: 23px;
  }

  .c-headline__headline {
    line-height: (32/23);
    font-size: 23px;
  }

  .c-headline__text {
    margin-top: 40px;
  }
}

@include bp(large) {
  .c-headline__content {
    max-width: (802/1112) * 100%;
  }

  .c-headline--image .c-headline__media {
    width: (674/1112) * 100%;
  }

  .c-headline--image .c-headline__content {
    width: (418/1112) * 100%;
  }

  .c-headline__headline {
    line-height: (40/30);
    font-size: 30px;
  }

  .c-headline__cta {
    margin-top: 40px;
  }
}

@include bp(xlarge) {
  .c-headline--image .c-headline__media {
    margin-right: -(80 - 53) + px;
  }
}
